import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';

import './ModalEditWindow.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Close } from '@mui/icons-material';

import { closeRequestModal } from '../../actions/modals';
import 'react-toastify/dist/ReactToastify.css';
import usePhoneCheck from '../../hooks/usePhoneCheck';

const switcherSchema = {
    SpeedTableModal: 'lg',
    GroupHistoryModal: 'md',
    statisticsData: 'lg',
    PaymentHistoryModal: 'md',
    ActiveTransactions: 'lg',
    SorokoinsForHomework: 'lg',
    AllActiveTransactions: 'lg',
    AllActiveRootTransactions: 'lg',
    AllActiveOrders: 'lg',
    Cart: 'md',
    OrderedProducts: 'lg',
};

const isModalHaveProps = ['PreventRouterModal'];

const ModalEditWindow = React.memo((props) => {
    const [modalComponent, setModalComponent] = useState(null);
    const [modalType, setModalType] = useState(null);

    useEffect(() => {
        processModalUpdate();
    }, [props.requestmodal]);

    async function processModalUpdate() {
        const { data } = props.requestmodal;

        if (data && data.modaltype) {
            const { modaltype } = data;
            try {
                const module = await import(`./modalComponents/${modaltype}`);
                const ModalComponent = module.default;
                const propsToPass = isModalHaveProps.includes(modaltype)
                    ? props
                    : {};
                const newModalComponent = (
                    <ModalComponent data={data} {...propsToPass} />
                );
                setModalComponent(newModalComponent);
                setModalType(modaltype);
            } catch (error) {
                console.error(
                    `Could not load component for type "${modaltype}"`,
                    error
                );
                setModalComponent(null);
                setModalType(null);
            }
        } else {
            setModalComponent(null);
            setModalType(null);
        }
    }

    const { requestmodal, closeRequestModal, modal, theme } = props;
    const { opened, data } = requestmodal;
    const animations = modal.usermenu ? 'animated fadeOut' : 'animated fadeIn';
    const mobile = usePhoneCheck();

    const modalWidth = mobile ? 'lg' : switcherSchema[data?.modaltype] || 'xs';
    return (
        <Dialog
            fullWidth={true}
            fullScreen={mobile}
            maxWidth={mobile ? false : modalWidth}
            open={opened || false}
            onClose={closeRequestModal}
            className={`panel-back themeColor${theme.toUpperCase()} ${
                modal.switch || animations
            }`}
        >
            <DialogTitle
                display="flex"
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography>{opened && data?.header}</Typography>
                <IconButton
                    id="close_modal-button"
                    aria-label="close"
                    onClick={closeRequestModal}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingBottom: '100px', paddingTop: 10 }}>
                {modalComponent}
            </DialogContent>
        </Dialog>
    );
});

const widthConnect = connect(
    (state) => ({
        user: state.user,
        requestmodal: state.modalWindow.requestmodal,
        modal: state.modalWindow,
        theme: state.user && state.user.theme,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                closeRequestModal,
            },
            dispatch
        )
);

export default widthConnect(ModalEditWindow);
